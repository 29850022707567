footer.lunch-footer {
    background-color: #06052D;
    height: 23rem;
    margin-top: 4rem;
    min-width: 70rem;
}


.lunch-end-container {
    text-align: center;
}

.lunch-comment {
    padding-top: 4rem;
    font-size: 2.1rem;
    color: #fff;
    font-weight: bold;
}

#lunch-ask-btn {
    margin: 4rem auto 0 auto;
    font-size: 1.6rem;
    padding: 0.6rem 2rem;
    color: #000;
    background-color: #fff;
}

.lunch-footer .back-btn-container {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 5rem;
}

.lunch-footer .back-btn-container a {
    color: #fff;
}