.lecture-container {
    width: 70rem;
    margin: auto;
    margin-top: 5rem;
}

.lecture-container h2 {    
    margin: auto;
    display: block;
    font-weight: bold;
    width: fit-content;
    margin-bottom: 3rem;
}

.lecture-container ul {
    margin-top: 2rem;
    margin-left: 5rem;
}

.lecture-container li div {
    display: flex;
}

li .lecture-title {
    font-size: 1.2rem;
    height: fit-content;
    margin: auto;
    margin-left: 0;
    margin-right: 0;
}

.lecture-title .lecture-tag {
    margin-top: auto;
    padding-left: 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: #6E7FB9;
}

.lecture-container .img-container {
    display: block;
    width: 13rem;
    height: 2.8rem;
}

.lecture-container img {
    display: block;
    height: 3rem;
    margin: auto;
}


.zerobase img {
    height: 2.5rem;
    margin-top: 0.3rem;
}

.custom .img-container {
    display: block;
    width: 13rem;
    height: 3rem;
    text-align: center;
}

ul.custom .custom-lecture {
    display: block;
    margin: auto;
    margin-top: 0.9rem;    
    font-weight: bold;
    font-size: 1.3rem;
}

#lecture-btn {
    margin: 5rem auto;
    padding: 0.6rem 2.5rem;
    font-size: 1.5rem;
}