div.Main {
    min-width: 70rem;
    max-width: 80rem;
    margin: auto;
    padding: 2rem 0;
}

.from-container {
    margin-left: 5rem;
}

.from-container div {
    display: flex;
}

.from-container .user-name-container {
    margin-bottom: 0.8rem;
}

.from-container .user-email-container {
    margin-bottom: 1rem;
}

.from-container label {
    width: 20rem;
    height: fit-content;
    font-size: 1.4rem;
    font-weight: bold;
    color: #06052D;
    text-align: right;
    margin: auto 0;
}

.from-container label span {
    font-size: 1.3rem;
    color: #BC0000;
}

.from-container .email-id {
    font-family: 'inter', sans-serif;
    font-size: 1.1rem;
    margin-left: 0.2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 7rem;
}

.from-container .at {
    font-size: 1.1rem;
    font-weight: bold;
    color: #06052D;
    margin: auto 0;
}

.from-container .email-domain {
    font-family: 'inter', sans-serif;
    font-size: 1.1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 7rem;
    margin-right: 0.7rem;
}

.from-container select {
    font-family: 'inter', sans-serif;
    font-size: 1.1rem;
    width: 10rem;
}

.to-container {
    display: flex;
    margin-left: 5rem;
    margin-top: 4rem;
}

.to-container label {
    display: block;
    width: 20rem;
    margin-top: 0.1rem;
}

.to-container label div {
    width: fit-content;
    margin-left: auto;
    margin-right: 0.3rem;
    text-align: right;
    font-size: 1.4rem;
    font-weight: bold;
    color: #06052D;
}

.to-container label .email a {
    font-size: 1.2rem;
    font-weight: bold;
    color: #818181;
}

.to-container label .email-desc {
    font-size: 1rem;
    font-weight: bold;
    color: #818181;
}

.text-container {
    position: relative;
    margin-left: 0.2rem;
}

.text-container .text-desc {
    position: absolute;
    width: 40rem;
    padding-top: 1rem;
    padding-left: 0.6rem;
}

.text-container .text-desc p {
    font-size: 0.7rem;
    color: #BC0000;
    padding-bottom: 0.1rem;
}

.text-container textarea {
    position: absolute;
    top: 0;
    font-size: 0.9rem;
    font-family: 'inter', sans-serif;
    width: 40rem;
    height: 20rem;
    padding-top: 4rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    border-color: #818181;
    resize: none;
}

.img-attach-container {
    margin: 21rem auto 0 auto;
    width: fit-content;
}

.img-attach-container .img-attach-col {
    width: fit-content;
    display: none;
}

.img-attach-container div:first-child {
    margin-bottom: 0.6rem;
}

.img-attach-container span {
    display: inline-block;
    font-size: 1.2rem;
    color: #818181;
    width: 9rem;
    text-align: center;
    vertical-align: middle;
}

.img-attach-container label {
    display: inline-block;
    background-color: #06052D;
    height: 1.5rem;
    vertical-align: middle;
}

.img-attach-container .img-name {
    display: inline-block;
    width: 12rem;
    border-style: solid;
    border-width: 1px;
    border-color: #818181;
    font-size: 1rem;
    color: #818181;
    padding: 0.2rem 0.4rem;
    vertical-align: middle;
}

.img-attach-container img {
    height: 100%;
}

#send-email-btn {
    margin: 2rem auto 0 auto;
    font-size: 1.5rem;
    padding: 0.2rem 2rem;
}

.img-attach-container .back-btn-container {
    margin-top: 1.5rem;
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 7rem;
}