#skillstack-wrap {
    padding: 3rem;
    background-color: #EDEDED;
    width: 64rem;
    margin: 0 auto;
}

.skillstack-container {
    width: 64rem;
    margin: auto;
    margin-top: 1rem;
}

.lang-container {
    width:fit-content;
    margin: auto;
}

.lang-container img{
    height: 6rem;
    vertical-align: middle;
}

.lang-container img:first-child ~ img{
    margin-left: 3rem;
}

.mod-container {
    width:fit-content;
    margin: auto;
    margin-top: 2rem;
}

.mod-container img {
    vertical-align: middle;
}

.django-img {
    width: 12rem;
}

.nodejs-img {
    width: 16rem;
}

.spring-img {
    width: 17rem;
}

.sklearn-img {
    width: 10rem;
}

.django-img ~ img{
    margin-left: 3rem;
}

.lib-container {
    width:fit-content;
    margin: auto;
    margin-top: 2rem;
}

.lib-container img {
    vertical-align: middle;
    height: 5.3rem;
}

.lib-container img:first-child ~ img{
    margin-left: 2rem;
}


.tags-container {
    height: 15rem;
    margin-top: 2rem;
}

.tags-row {
    width: fit-content;
    margin: auto;
    padding-top: 3rem;
}

.tags-row ul li::before {
    content: "#";
}

.tags-row ul li {
    display: inline;
    padding: 0.4rem 0.5rem;
    font-size: 2rem;
    background-color: #65B5FF;
    font-weight: bold;
    border-radius: 1rem;
}

.tags-row ul li:first-child ~ li {
    margin-left: 2rem;
}

#ask-lecture-btn {
    margin: auto;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    padding: 0.8rem 2.6rem;
}