.feynman-container {
    position: fixed;
    left: calc(100vw - 19rem);
    bottom: 0;
    padding-bottom: 4rem;
}

.feynman-container:hover {
    cursor: pointer;
}


.feynman-container img {
    width: 15rem;
    border-radius: 2.5rem;
    border-width: 1px;
    border-color: black;
    border-style: solid;
}

.feynman-container .forehead {
    position: absolute;
    width: 100%;
    top: 0.8rem;
    text-align: center;
    font-weight: bold;
    font-size: 1.3rem;
}

.feynman-container .neck {
    position: absolute;
    width: 100%;
    bottom: 6.7rem;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
}

.feynman-container .neck .email {
    font-size: 1rem;
}