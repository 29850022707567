.qna-container {
    width: 70rem;
    margin: auto;
}

ul.questions {
    margin-top: 4rem;
}

ul.questions p {
    color: #06052D;
    width: fit-content;
    margin: auto;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    font-size: 1.4rem;
}

div.answer {
    width: fit-content;
    margin: auto;
    margin-top: 2rem;
    font-weight: bold;
    font-size: 2rem;
}

a.email {
    padding-left: 0.6rem;
    font-size: 1.8rem;
}

#ask-btn {
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 5rem;
    font-size: 2rem;
    padding: 0.6rem 3rem;
}

