.lectureapp-main {
    width: 100%;
}

.lectureapp-main .lectureapp-item {
    width: 70rem;
    margin: 0 auto;
}

.lectureapp-main .lectureapp-item article .logo {
    width: 17rem;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.lectureapp-main article .logo img {
    display: block;
    width: 100%;
}

.lectureapp-main article .poster {
    width: 50rem;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.lectureapp-main article .poster img {
    display: block;
    width: 100%;
}

.lectureapp-main article .title {
    font-size: 2rem;
    text-align: center;
    margin: 2rem 0 4rem 0;
}

.lectureapp-main article .title a {
    font-weight: bold;
}

.lecture-end-container {
    width: 70rem;
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
}



.lecture-end-container p {
    font-size: 1.2rem;
    color: #06052D;
    padding: 0.1rem;
    text-align: center;
}

#lecture-ask-btn {
    padding: 0.4rem 2rem;
    font-size: 2rem;
    margin: 2rem auto 0 auto;
}

.lecture-end-container .back-btn-container {
    margin-top: 1.5rem;
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 7rem;
}