#lecture-header-wrap {
    background-color: #06052D;
    height: fit-content;
    border-radius: 0 0 1.5rem 1.5rem;
    min-width: 70rem;
}

#lecture-header-wrap div.lecture-header {
    max-width: 70rem;
    margin: auto;
    padding: 3rem 2rem;
}

#lecture-header-wrap div.lecture-header h1 {
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    margin-left: 5rem;
}