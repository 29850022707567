#lunchapp-header-wrap {
    display: flex;
    margin: 0 auto;
}

div.lunchapp-header {
    display: table;
    position: relative;
    width: fit-content;
    margin: 6rem 0 0 0;
    height: 5rem;
    padding-left: calc(50vw - 45rem);
    padding-right: 9rem;
    background-color: #D9D9D9;
}

div.lunchapp-header::after {
    position: absolute;
    content:"";
    margin-right: 0;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-right: 3.5rem solid #fff;
    border-top: 2.5rem solid transparent;
    border-bottom: 2.5rem solid transparent;
}

div.lunchapp-header .line {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

div.lunchapp-header h1 {
    color: #06052D;
    font-size: 2rem;
    font-weight: bold;
    line-height: 100%;
    margin-left: 5rem;
}