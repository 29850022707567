.career-container {
    width: 70rem;
    margin: 0 auto;
    margin-top: 5rem;
}

.career-container h2 {
    width: fit-content;
    margin: 0 auto;
}

.career-item h3 {
    width: fit-content;
    margin: auto;
    margin-top: 3rem;
}

.career-timer-container {
    width: fit-content;
    margin: auto;
    margin-top: 1rem;
    font-size: 2.1rem;
    font-weight: bold;
    color: #6E7FB9;
}

#career-timer {
    font-size: 2.7rem;
    padding-left: 0.5rem;
}

.company-wrap {
    width: 70rem;
    margin: auto;
    margin-top: 2rem;
}

ul.company {
    width: 50rem;
    margin: auto;
    margin-left: 15rem;
}

ul.company li div {
    display: flex;
}

ul.company .status{
    margin-left: auto;
    display: flex;
    align-items: center;
    font-weight: bold;
}

ul.company .img-container {
    height: 2.5rem;
    width: 14rem;
    font-size: 1.5rem;
}

ul.company img {
    height: 2rem;
    margin: auto 0;
    margin-right: 0;
}

ul.company img.zerobase{
    height: 2.3rem;
}

ul.company .custom {
    display: flex;
    margin: auto;
    margin-right: 1rem;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
}

ul.company li .blank {
    color: #fff;
}

ul.company .desc {
    display: inline;
    height: fit-content;
    margin: auto;
    margin-left: 1rem;
    font-size: 1.3rem;
}

.proj-container {
    display: flex;
    width: 70rem;
    margin: auto;
    margin-top: 2rem;
}

.proj-container .custom {    
    font-size: 1.6rem;
    font-weight: bold;
    height: fit-content;
    margin: auto 0;
    margin-left: 18.5rem;
    padding: 0 2rem;
}

.proj-list li {
    font-size: 1.3rem;
    padding: 0.5rem 0;
}



div.edu-special {
    display: flex;
    width: fit-content;
    color: #818181;
    font-weight: bold;
    padding: 0.8rem 0;
    margin: auto;
    margin-top: 0.5rem;
    font-size: 1.2rem;
}

ul.edu-list {
    width: 70rem;
    margin: auto;
    margin-top: 0.5rem;
    margin-bottom: 3rem;
    font-size: 1.4rem;
}

.edu-list li {
    display: flex;
    width: fit-content;
    padding: 0.5rem 0;
    margin: auto;
}



#paper-btn {
    margin: auto;
    margin-top: 1.5rem;
    margin-bottom: 5rem;
    border-radius: 0;
    padding: 0.5rem 1.5rem;
    background-color: #fff;
    color: #000;
    font-size: 1.5rem;
    border-width: 1px;
}

#paper-btn:hover {
    background-color: rgb(201, 201, 201);
}

#paper-btn:active {
    background-color: rgb(156, 156, 156);
}


#paperlist-wrap .paperlist-container {
    position: fixed;
    background-color: #E2E2E2;
    top: 20%;
    width: 50rem;
    left: calc(50% - 29rem);
    padding: 1.5rem 4rem;
    border-width: 1px;
    border-style: solid;
}

.paperlist-container h2 {
    display: inline-block;
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.paperlist-container p {
    font-size: 1.4rem;
    font-weight: normal;
    padding-left: 4rem;
    margin-bottom: 1rem;
}

.paperlist-container ul {
    padding-left: 4rem;
}

.paperlist-container li {
    font-size: 1.2rem;
    padding: 0 0 1rem 0;
}

#paperlist-close-btn {
    font-size: 2rem;
    margin: 2rem auto 0.2rem auto;
    padding: 0.2rem 3rem;
    color: #000;
    background-color: #fff;
    border-width: 1px;
}

#paperlist-close-btn:hover {
    background-color: rgb(201, 201, 201);
}

#paperlist-close-btn:active {
    background-color: rgb(156, 156, 156);
}