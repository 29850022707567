#mail-header-wrap {
    background-color: #06052D;
    height: fit-content;
    border-radius: 0 0 1.5rem 1.5rem;
    min-width: 70rem;
}

div.mail-header {
    min-width: 70rem;
    max-width: 80rem;
    margin: auto;
    padding: 3rem 2rem;
}

div.mail-header h1 {
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    margin-left: 7rem;
}