footer {
    background-color: #06052D;
    height: 24rem;
    margin-top: 3.5rem;
    min-width: 70rem;
}

.footer-container {
    position: relative;
    color: #fff;
    max-width: 100rem;
    height: calc( 100% - 3.5rem );
    margin: 0 auto;
    padding-top: 3.5rem;
    padding-left: 3rem;
}

div.about {
    font-size: 1.5rem;
}

.contact {
    margin-top: 2rem;
}

.contact p {
    font-weight: normal;
}


.about-text {
    margin: 2rem 0;
}

.about-text p {
    font-weight: normal;
    padding: 0.1rem 0;
}



.bonus-btn-wrap {
    margin-bottom: 2rem;
}

.bonus-btn-container {
    display: flex;
    padding-top: 0.6rem;
    align-items: flex-end;
}

#bonus-btn {
    font-weight: bold;
}

#bonus-btn:hover {
    cursor: pointer;
}

.bonus-btn-container span {
    font-size: 0.8rem;
    padding-left: 0.3rem;
}



.copyright {
    position: absolute;   
    right: 1rem;
    bottom: 1rem;
}