.lunchapp-main {
    width: 70rem;
    margin: 0 auto;
    padding-right: 8rem;
}

.lunch-item-container {
    width: fit-content;
    margin: 4rem auto 0 auto;
}

.lunch-item {
    display:flex;
    margin-top: 3rem;
}

.lunch-item .category {
    width: 16rem;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin: auto 0;
}

.lunch-item .category .desc {
    margin-top: 0.8rem;
    font-size: 1.3rem;
    color: #818181;
}

ul.choice-list li {    
    padding: 0.3rem 0;
    font-size: 1.3rem;
}

ul.choice-list li div {
    display: inline-block;
}

ul.choice-list li div.choice {
    width: 15rem;
    text-align: right;
    padding-right: 2rem;
}

ul.choice-list li div.choice span {
    padding-left: 0.4rem;
    font-weight: bold;
}

ul.choice-list li div.option1 {
    min-width: 14rem;
    text-align: left;
    padding-right: 2rem;
    font-weight: bold;
}

ul.choice-list li div.option1 span {
    padding-left: 0.4rem;
    font-weight: normal;
}

ul.choice-list li div.option2 {
    min-width: 14rem;
    text-align: left;
    padding-right: 2rem;
    font-weight: bold;
}

ul.choice-list li div.option2 span {
    padding-left: 0.4rem;
    font-weight: normal;
}

div.michelin {
    margin-top: 4rem;
}

div.category .title {
    width: 22rem;
}

div.michelin .category {
    width: 22rem;
}

div.michelin .category .title > div {
    width: 20rem;
    padding-top: 0.2rem;
}

.lunch-item div.desc-container {
    display:flex;
    width: 22rem;
}

.lunch-item div.desc-container .desc {
    width: 100%;
    margin-top: 0.5rem;
}

.img-mascot-container {
    display: inline-block;
    margin-left: auto;
    width: 5.5rem;
}

.img-mascot-container img{
    width: 100%;
}

#embed-map {
    margin-left: 1rem;
    width: 45rem;
}


#embed-map iframe {
    width: 52rem;
    height: 30rem;
}
