.etc-container {
    width: 70rem;
    margin: auto;
    margin-top: 4rem;
}

.etc-container h2 {
    text-align: center;
}

.evenmydogs-container {
    margin-top: 3rem;
}

.evenmydogs-container .evenmydogs-logo {
    display: flex;
    margin-bottom: 1rem;
}

.evenmydogs-container .img-container {
    display: block;
    font-size: 1.5rem;
    margin-left: auto;
}

.evenmydogs-logo img {
    height: 7rem;
    margin: auto 0;
}

.evenmydogs-container .desc {    
    font-size: 2rem;
    margin: auto;
    margin-left: 1rem;
}

.evenmydogs-container p {
    font-size: 1.5rem;
    font-weight: normal;
    text-align: center;
    padding-top: 0.3rem;
}

.character-container {
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.character-container h3{
    text-align: center;
    padding: 1rem;
}

.character-container p {
    font-size: 1.4rem;
    font-weight: normal;
    text-align: center;
    padding: 0.2rem;
}

.ask-text p {
    text-align: center;
    padding: 0.15rem;    
}

.ask-container .email {
    margin-top: 1.1rem;
    text-align: center;
    font-weight: bold;
}

#askany-btn {
    font-size: 1.4rem;
    margin: 0.4rem auto;
    padding: 0.5rem 3.3rem;
}