#headline-wrap {
    background-color: #06052D;
    color: #FFFF;
    min-width: 70rem;
    border-radius: 0 0 2rem 2rem;
}

.headline-container {
    position: relative;
    min-width: 70rem;
    max-width: 80rem;
    height: 30rem;
    margin: auto;
    padding-left: 3rem;
}

.name-container {
    display: flex;
    align-items: flex-end;
    padding-top: 9rem;
}

.name-kor {
    font-weight: bold;
}

.name-eng {
    display: inline;
    font-size: 5rem;
    vertical-align: bottom;
}

.headline-container .desc {
    padding-left: 1rem;
    margin-top: 1.5rem;
    font-size: 1.9rem;
}

.headline-container img {
    position: absolute;
    top: 0;
    right: 2rem;
    height: 100%;
}